exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-especialidade-prismic-especialidade-uid-tsx": () => import("./../../../src/pages/especialidade/{prismicEspecialidade.uid}.tsx" /* webpackChunkName: "component---src-pages-especialidade-prismic-especialidade-uid-tsx" */),
  "component---src-pages-exame-prismic-exames-uid-tsx": () => import("./../../../src/pages/exame/{prismicExames.uid}.tsx" /* webpackChunkName: "component---src-pages-exame-prismic-exames-uid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-prismic-buscar-exames-uid-tsx": () => import("./../../../src/pages/{prismicBuscarExames.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-buscar-exames-uid-tsx" */),
  "component---src-pages-prismic-contato-uid-tsx": () => import("./../../../src/pages/{prismicContato.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-contato-uid-tsx" */),
  "component---src-pages-prismic-pagina-especialidades-uid-tsx": () => import("./../../../src/pages/{prismicPaginaEspecialidades.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-pagina-especialidades-uid-tsx" */),
  "component---src-pages-prismic-quem-somos-uid-tsx": () => import("./../../../src/pages/{prismicQuemSomos.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-quem-somos-uid-tsx" */)
}

